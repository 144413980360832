import { KegStatus } from "types"
import { useGetInventoryKegsQuery } from "../../api"

export const useKegsOnPPSub = (ppSubId: string) => {
    const { data: assignedKegs, isLoading: assignedKegsLoading } =
        useGetInventoryKegsQuery({
            ppSubId,
            kegStatus: KegStatus.Assigned,
        })
    const { data: unassignedKegs, isLoading: unassignedKegsLoading } =
        useGetInventoryKegsQuery({
            ppSubId,
            kegStatus: KegStatus.Unassigned,
        })
    const { data: archivedKegs, isLoading: archivedKegsLoading } =
        useGetInventoryKegsQuery({
            ppSubId,
            kegStatus: KegStatus.Depleted,
        })
    const { data: tappedKegs, isLoading: tappedKegsLoading } =
        useGetInventoryKegsQuery({
            ppSubId,
            kegStatus: KegStatus.Tapped,
        })

    return {
        assignedKegs,
        unassignedKegs,
        archivedKegs,
        tappedKegs,
        isLoading:
            assignedKegsLoading ||
            unassignedKegsLoading ||
            archivedKegsLoading ||
            tappedKegsLoading,
    }
}
