
import { useMemo } from "react"
import { Keg, KegStatus, } from "types"
import { useGetKegsByStatusQuery } from "../../api"

export const useKegsByStatus = (ppSubId: string): {
    allKegs: Keg[]
    tappedKegs: Keg[]
    onDeckKegs: Keg[]
    unassignedKegs: Keg[]
    archivedItems: Keg[]
    isLoading: boolean
} => {

    const { data, isLoading } = useGetKegsByStatusQuery({ ppSubId }, { skip: !ppSubId })
    const allKegs = useMemo(() => data || [], [data])

    const { tappedKegs, onDeckKegs, unassignedKegs, archivedItems } = useMemo(() => {
        return allKegs.reduce((acc, keg) => {
            switch (keg.kegStatus) {
                case KegStatus.Tapped:
                    acc.tappedKegs.push(keg)
                    break
                case KegStatus.Assigned:
                    acc.onDeckKegs.push(keg)
                    break
                case KegStatus.Unassigned:
                    acc.unassignedKegs.push(keg); break
                case KegStatus.Depleted:
                    acc.archivedItems.push(keg)
                    break
                default:
                    break
            } return acc
        }, { tappedKegs: [], onDeckKegs: [], unassignedKegs: [], archivedItems: [] })
    }, [allKegs])

    return {
        allKegs,
        tappedKegs,
        onDeckKegs,
        unassignedKegs,
        archivedItems,
        isLoading
    }
}
